<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        title="Spelningar"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :items-per-page="itemsPerPage"
        no-data-text="Inga spelningar finns upplagda"
        no-results-text="Inga spelningar hittades"
        :page.sync="page"
        @click:row="onOpenUpdate"
        @page-count="pageCount = $event"
        hide-default-footer
        class="click-table"
        locale="sv-SE">
      </v-data-table>
    </v-container>
  </div>
</template>
  
<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import useCore from '../../helpers/core';
import ViewToolbar from '../../components/ViewToolbar.vue'

export default {
  name: 'eventPerformanceList',
  props: {
    evnId: {
      type: Number,
      required: false,
    }
  },
  components: {
    'app-view-toolbar': ViewToolbar,
  },
  setup({ evnId }) {
    const router = require('@/router/router').default;
    const { callApi } = useCore();

    const dataLoaded = ref(false);
    const tableData = reactive({
      headers: [
      { text: 'Scen', value: 'est_str_name' },
      { text: 'Från', value: 'epc_dat_start' },
      { text: 'Till', value: 'epc_dat_end' },
      { text: 'Band', value: 'epr_str_name' },
      ],
      items: [],
    });

    // Actions
    const onOpenCreate = () => {
      router.push({ name: 'eventPerformancePut', params: { id: 0 } });
    };
    const onOpenUpdate = (eventPerformance) => {
      router.push({ name: 'eventPerformancePut', params: { id: eventPerformance.epc_id } });
    };
    
    // Toolbar actions
    const toolbarButtons = [{ icon: 'plus', name: 'Ny slot', fn: onOpenCreate }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({ method: 'get', path: `/cms/events/${evnId}/performances` })
        .then((res) => {
          dataLoaded.value = true;
          tableData.items = res;
        })
        .catch((err) => {
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      // Data
      dataLoaded,
      tableData,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      // Actions
      toolbarButtons,
      onToolbarButton,
      onOpenUpdate,
    };
  },
};
</script>

<style>
</style>